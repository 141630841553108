import "./HistoryMVStyles.css";

function HistoryMV() {
  return (
    <div className="history-container">
      <h1>Historia</h1>
      <p>
        En 2019 un grupo de personas pertenecientes a la comunidad de Arcos del
        Espino, Usulután, se acercaron a la familia de uno de nuestros
        fundadores pidiendo ayuda para su gente. Gracias a ese pedido de
        auxilio, entre amigos nos unimos para apoyar a los necesitados y en
        Diciembre, 2019, fuimos unos 20 jóvenes a convivir con los miembros de
        esta comunidad. Durante 3 días entregamos comida, ropa y medicina a los
        habitantes de la zona, pero también jugamos con los niños y nos
        permitimos conocer las necesidades y preocupaciones de las personas. A
        los pocos meses empezó la pandemia y en Agosto 2020, cuando las personas
        del Espino estaban más necesitadas que nunca, formalizamos Jovenes por
        El Espino y cada mes hemos ido a repartir víveres a la comunidad. Con el
        tiempo crecimos en confianza con los habitantes y ellos nos permitieron
        conocer su estilo de vida, entrar a sus casas y tratarnos como hermanos.
        Hoy, después de casi 40 visitas a la comunidad, continuamos firmes en
        nuestra intención de ayudar a estas personas de la mejor manera que
        podamos, siempre con Dios presente.
      </p>

      <h1>Misión</h1>
      <p>
        Ser agentes de cambio en la comunidad Arcos de El Espino. Evangelizar y guiar a las familias según nuestras inspiraciones y posibilidades. Construir una iglesia católica que servirá para compartir la palabra de Dios y unir a la comunidad bajo principios y metas. Fomentar la asistencia a la escuela y promover el trabajo honesto.
      </p>

      <h1>Visión</h1>
      <p>
        Crear conciencia en nosotros y en quienes nos rodean que, con nuestros
        valores, habilidades y principios, todos podemos ayudar a la gente que
        lo necesita.
      </p>
    </div>
  );
}

export default HistoryMV;
